import React, { useEffect, useState } from "react";
import Sidebar from "../../components/ui/navbar/sidenav.components";
import "./add.pages.css"; 

const AddPlayer = () => {
  const [isAdding, setIsadding] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      timer = setTimeout(() => {
        setIsadding(true);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Sidebar />
      <main className="container-fluid">
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Veteran Players</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add New Players
                </li>
              </ol>
              <h4 className="main-title mb-0">Add Player Form</h4>
            </div>
            {/* <div className="d-flex gap-2 mt-3 mt-md-0">
              <button type="button" className="btn btn-white d-flex align-items-center gap-2">
                <i className="ri-share-line fs-18 lh-1"></i>Share
              </button>
              <button type="button" className="btn btn-white d-flex align-items-center gap-2">
                <i className="ri-printer-line fs-18 lh-1"></i>Print
              </button>
              <button type="button" className="btn btn-primary d-flex align-items-center gap-2">
                <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
                <span className="d-none d-sm-inline"> Report</span>
              </button>
            </div> */}
          </div>

          
          <form id="AddPlayerForm" name="AddPlayerForm" method="post" action="" encType="multipart/form-data" className="add-player-form">
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State/Institution</label>
                    <select name="StateId" id="StateId" className="form-control">
                      <option value="">-----Select State/Institution-----</option>
                      <option value="14">Andhra Pradesh</option>
                    </select>
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Name (Pls put initials after name)</label>
                    <input type="text" name="PName" className="form-control" id="PName" placeholder="Please put initials after Name" />
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Father's Name</label>
                    <input type="text" name="FName" className="form-control" id="FName" placeholder="Enter Father's Name." />
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Mother's Name</label>
                    <input type="text" name="MName" className="form-control" id="MName" placeholder="Enter Mother's Name." />
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Gender</label>
                    <select name="Gender" id="Gender" className="form-control">
                      <option value="">-----Select Gender-----</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Date Of Birth</label>
                    <input type="date" name="DateOfBirth" className="form-control" id="DateOfBirth" />
                    <span className="help-block text-red"></span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="Email" className="form-control" id="Email" placeholder="Enter Player Email" />
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Contact Number</label>
                    <input type="text" name="ContactNumber" className="form-control" id="ContactNumber" placeholder="Enter Player Contact Number" />
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Size of T-Shirt</label>
                    <select name="TShirt" id="TShirt" className="form-control">
                      <option value="">-----Select T-Shirt Size-----</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                      <option value="XXXL">XXXL</option>
                    </select>
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Player Image</label>
                    <input type="file" name="PlayerImage" id="PlayerImage" />
                    <p className="help-block">Only jpg, png or jpeg file and less than 50 KB will be accepted. Dimensions (150px X 150px)</p>
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Upload DOB Cert or ID Proof</label>
                    <input type="file" name="UploadImage" id="UploadImage" />
                    <p className="help-block">Only jpg, png or jpeg file and less than 250 KB will be accepted.</p>
                    <span className="help-block text-red"></span>
                  </div>

                  <div className="form-group">
                    <label>Permanent Address</label>
                    <textarea name="Address" id="Address" className="form-control" placeholder="Enter Player Address"></textarea>
                    <span className="help-block text-red"></span>
                  </div>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>

          <div className="main-footer mt-5">
            <span>© 2024. Futuregen. All Rights Reserved.</span>
            <span>Created by: <a href="https://futuregen.co.in" target="_blank" rel="noopener noreferrer">Futuregen</a></span>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddPlayer;
