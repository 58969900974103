import React from 'react';
import './App.css';
import { AppRouter } from './components/router/AppRouter';
import "../src/assets/css/colors.css";
import "../src/assets/css/styles.css";
import "../src/assets/js/main.js"
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import '../src/assets/css/style.min.css';
import '../src/assets/css/remixicon.css';
                      



function App() {
  return (
    <>
            <AppRouter />
</>
  );
}

export default App;
