import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../../pages/dashboard/dashboard";
import LoginScreen from "../../pages/login/loginScreen.page";
import Approved from "../../pages/approvedPlayers/approved.page";
import AddPlayer from "../../pages/addPlayers/add.pages";
import Pending from "../../pages/pendingPlayers/pending.page";
import TournamentsList from "../../pages/tournamentList/tournamentList.page";
import MenSingle from "../../pages/Singles/menSingle.page";
import MenDouble from "../../pages/Doubles/menDoubles.pages";
import MenTeam from "../../pages/Team/menTeam.pages";
import MixedDouble from "../../pages/mixedDoubles/mixedDoubles.pages";
import WomenSingle from "../../pages/Singles/WomenSingle.page";
import WomenDouble from "../../pages/Doubles/womenDoubles.page";
import WomenTeam from "../../pages/Team/womenTeam.page";



export const AppRouter: React.FC = () => {
  const isAuthenticated = !!localStorage.getItem("accessToken");

  return (
    <BrowserRouter>
      <Routes>

        <Route
          path="/"
          element={<LoginScreen />}
        />

        <Route
          path="/dashboard"
          element={<Dashboard />}
        />

        <Route
          path="/approved"
          element={<Approved />} />

        <Route
          path="/pending"
          element={<Pending />} />

        <Route
          path="/addPlayer"
          element={<AddPlayer />} />

        <Route
          path="/tournaments"
          element={<TournamentsList />} />

        <Route path="/menSingle/:ageGroup" element={<MenSingle />} />
        <Route path="/menDoubles/:ageGroup" element={<MenDouble />} />
        <Route path="/menTeam/:ageGroup" element={<MenTeam />} />
        <Route path="/mixedDoubles/:ageGroup" element={<MixedDouble />} />
        <Route path="/womenSingle/:ageGroup" element={<WomenSingle />} />
        <Route path="/womenDoubles/:ageGroup" element={<WomenDouble />} />
        <Route path="/womenTeam/:ageGroup" element={<WomenTeam />} />


      </Routes>
    </BrowserRouter>
  );
};
