import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Sidebar from "../../components/ui/navbar/sidenav.components";

const Dashboard = () => {
  const [isAdding, setIsadding] = useState(false); // Loader state..


  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      // Add a delay of 500ms before making the API call
      timer = setTimeout(() => {
        
        setIsadding(true);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);
  return (
    <>

    <Sidebar/>
      <main className="container-fluid" >
      <div className="main main-app p-3 p-lg-4">
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
          </ol>
          <h4 className="main-title mb-0">Welcome to Dashboard</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <button type="button" className="btn btn-white d-flex align-items-center gap-2"><i className="ri-share-line fs-18 lh-1"></i>Share</button>
          <button type="button" className="btn btn-white d-flex align-items-center gap-2"><i className="ri-printer-line fs-18 lh-1"></i>Print</button>
          <button type="button" className="btn btn-primary d-flex align-items-center gap-2"><i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span></button>
        </div>
      </div>

      <div className="row g-3">
        <div className="col-sm-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body overflow-hidden px-0">
              <div className="crypto-item">
                <div className="avatar bg-orange"><i className="cf cf-btc"></i></div>
                <div className="crypto-body">
                  <label className="card-label">Bitcoin <span>(BTC)</span></label>
                  <h6 className="card-value">$3,972.87</h6>
                </div> 
              </div> 
              <div id="apexChart1" className="apex-chart-ten"></div>
            </div> 
            <div className="card-footer card-footer-crypto pt-0">
              <div><strong>12</strong> USD Markets</div>
              <div><strong>80</strong> BTC Markets</div>
            </div> 
          </div> 
        </div> 
        <div className="col-sm-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body overflow-hidden px-0">
              <div className="crypto-item">
                <div className="avatar bg-twitter"><i className="cf cf-etc"></i></div>
                <div className="crypto-body">
                  <label className="card-label">Ethereum <span>(ETC)</span></label>
                  <h6 className="card-value">$136.99</h6>
                </div> 
              </div> 
              <div id="apexChart2" className="apex-chart-ten"></div>
            </div> 
            <div className="card-footer card-footer-crypto pt-0">
              <div><strong>10</strong> USD Markets</div>
              <div><strong>65</strong> ETH Markets</div>
            </div> 
          </div> 
        </div> 
        <div className="col-sm-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body overflow-hidden px-0">
              <div className="crypto-item">
                <div className="avatar bg-success"><i className="cf cf-btc"></i></div>
                <div className="crypto-body">
                  <label className="card-label">Bitcoin Cash <span>(BCH)</span></label>
                  <h6 className="card-value">$160.43</h6>
                </div> 
              </div> 
              <div id="apexChart3" className="apex-chart-ten"></div>
            </div> 
            <div className="card-footer card-footer-crypto pt-0">
              <div><strong>12</strong> USD Markets</div>
              <div><strong>74</strong> BCH Markets</div>
            </div> 
          </div> 
        </div> 
        <div className="col-sm-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body overflow-hidden px-0">
              <div className="crypto-item">
                <div className="avatar bg-primary"><i className="cf cf-dash"></i></div>
                <div className="crypto-body">
                  <label className="card-label">Dash <span>(DASH)</span></label>
                  <h6 className="card-value">$90.58</h6>
                </div> 
              </div> 
              <div id="apexChart4" className="apex-chart-ten"></div>
            </div> 
            <div className="card-footer card-footer-crypto pt-0">
              <div><strong>16</strong> USD Markets</div>
              <div><strong>31</strong> DASH Markets</div>
            </div> 
          </div> 
        </div> 
      </div> 

      <div className="main-footer mt-5">
        <span>© 2024. Futuregen . All Rights Reserved.</span>
        <span>Created by: <a href="https://futuregen.co.in" target="_blank">Futuregen</a></span>
      </div> 
    </div>
     
      </main>
    </>
  );
};

export default Dashboard;
