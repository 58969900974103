import React, { useEffect, useState } from 'react';
import './sidenav.components.css';
import profileimg from "../../../assets/images/img10.jpg"

interface SubItem {
  name: string;
  link: string;
}

interface Item {
  name: string;
  icon: string;
  link: string;
  active?: boolean;
  hasSub?: boolean;
  subItems?: SubItem[];
}

interface NavGroup {
  label: string;
  items: Item[];
}

const Sidebar = () => {
  // State to manage which submenus are open
  const [openSubMenus, setOpenSubMenus] = useState<{ [key: number]: boolean }>({});
  const [activeItem, setActiveItem] = useState<string | null>(null); // State to manage active items
  const [activeGroup, setActiveGroup] = useState<string | null>(null); // State to manage active items

  const [footerMenuVisible, setFooterMenuVisible] = useState(false);
  const toggleFooterMenu = () => {
    setFooterMenuVisible(!footerMenuVisible);
  };
  const toggleSubMenu = (index: number) => {
    setOpenSubMenus((prev) => ({ ...prev, [index]: !prev[index] })); // Toggle the current submenu
  };

  const handleItemClick = (itemName: string) => {
    setActiveItem(itemName); // Set the active item
  };

  const navGroups: NavGroup[] = [
    {
      label: 'Veteran Players',
      items: [
        { name: 'Approved Players', icon: 'bi bi-arrow-right-short', link: '/approved' },
        { name: 'Pending for Approval', icon: 'vaadin-line-h', link: '/pending' },
        { name: 'Add new Players', icon: 'ri-calendar-line', link: '/addPlayer' },
      ],
    },
    {
      label: 'Tournaments',
      items: [
        { name: 'Tournament List', icon: 'ri-folder-2-line', link: '/tournaments' },
      ],
    },
    {
      label: 'Veteran 40+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/40+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/40+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/40+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/40+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/40+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/40+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/40+' },
      ],
    },
    {
      label: 'Veteran 50+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/50+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/50+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/50+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/50+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/50+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/50+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/50+' },
      ],
    },
    {
      label: 'Veteran 60+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/60+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/60+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/60+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/60+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/60+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/60+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/60+' },
      ],
    },
    {
      label: 'Veteran 65+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/65+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/65+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/65+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/65+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/65+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/65+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/65+' },
      ],
    },
    {
      label: 'Veteran 70+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/70+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/70+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/70+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/70+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/70+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/70+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/70+' },
      ],
    },
    {
      label: 'Veteran 75+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/75+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/75+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/75+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/75+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/75+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/75+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/75+' },
      ],
    },
    {
      label: 'Veteran 80+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/80+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/80+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/80+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/80+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/80+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/80+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/80+' },
      ],
    },
    {
      label: 'Veteran 85+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/85+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/85+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/85+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/85+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/85+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/85+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/85+' },
      ],
    },
    {
      label: 'Veteran 90+',
      items: [
        { name: `Men's Singles`, icon: 'ri-folder-2-line', link: '/menSingle/90+' },
        { name: `Women's Singles`, icon: 'ri-mail-send-line', link: '/womenSingle/90+' },
        { name: `Men's Doubles`, icon: 'ri-calendar-line', link: '/menDoubles/90+' },
        { name: `Women's Doubles`, icon: 'ri-question-answer-line', link: '/womenDoubles/90+' },
        { name: `Mixed Doubles`, icon: 'ri-contacts-book-line', link: '/mixedDoubles/90+' },
        { name: `Men's Team`, icon: 'ri-checkbox-multiple-line', link: '/menTeam/90+' },
        { name: `Women's Team`, icon: 'ri-checkbox-multiple-line', link: '/womenTeam/90+' },
      ],
    },
    // Additional navGroups can be added here...
  ];

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [skinMode, setSkinMode] = useState<string | null>(localStorage.getItem('skin-mode'));
  const [sidebarSkin, setSidebarSkin] = useState<string | null>(localStorage.getItem('sidebar-skin'));
  const [notifications] = useState([
    {
      id: 1,
      avatar: profileimg, // Profile image of the user or committee member
      message: 'Roger Federer and 100 other players reacted to your comment on the new tournament rules.',
      time: 'Sept 20 10:00am',
    },
    {
      id: 2,
      avatar: profileimg,
      message: 'Serena Williams tagged you and 5 others in a post about upcoming committee meeting.',
      time: 'Sept 18 11:30am',
    },
    {
      id: 3,
      avatar: profileimg,
      message: 'New tournament schedule has been released. Check your match times for this weekend.',
      time: 'Sept 15 09:00pm',
    },
    {
      id: 4,
      avatar: profileimg,
      message: 'Reminder: The Tennis Committee invited you to join the Tournament Review Meeting on Oct 5.',
      time: 'Sept 14 01:45pm',
    },
    {
      id: 5,
      avatar: profileimg,
      message: 'Maria Sharapova reacted to your proposal about increasing player participation.',
      time: 'Sept 10 07:20am',
    },
  ]);


  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    document.body.classList.toggle('sidebar-hide', !sidebarVisible);
  };

  const handleSkinChange = (mode: string) => {
    setSkinMode(mode);
    document.documentElement.setAttribute('data-skin', mode === 'dark' ? 'dark' : '');
    localStorage.setItem('skin-mode', mode);
  };

  const handleSidebarSkinChange = (skin: string) => {
    setSidebarSkin(skin);
    const sidebarElement = document.querySelector('.sidebar');
    if (sidebarElement) {
      sidebarElement.className = `sidebar sidebar-${skin}`;
    }
    localStorage.setItem('sidebar-skin', skin);
  };

  useEffect(() => {
    if (skinMode) {
      document.documentElement.setAttribute('data-skin', skinMode === 'dark' ? 'dark' : '');
    }
    if (sidebarSkin) {
      const sidebarElement = document.querySelector('.sidebar');
      if (sidebarElement) {
        sidebarElement.className = `sidebar sidebar-${sidebarSkin}`;
      }
    }
  }, [skinMode, sidebarSkin]);
  const pathToNavItemMap: { [key: string]: { itemName: string, groupIndex: number } } = {
    '/pending': { itemName: 'Pending for Approval', groupIndex: 0 },
    '/approved': { itemName: 'Approved Players', groupIndex: 0 },
    '/addPlayer': { itemName: 'Add new Players', groupIndex: 0 },
    '/tournaments': { itemName: 'Tournament List', groupIndex: 1 },
    '/menSingle/40+': { itemName: `Men's Singles`, groupIndex: 2 },
    '/menSingle/50+': { itemName: `Men's Singles`, groupIndex: 3 },
    '/menSingle/60+': { itemName: `Men's Singles`, groupIndex: 4 },
    '/menSingle/65+': { itemName: `Men's Singles`, groupIndex: 5 },
    '/menSingle/70+': { itemName: `Men's Singles`, groupIndex: 6 },
    '/menSingle/75+': { itemName: `Men's Singles`, groupIndex: 7 },
    '/menSingle/80+': { itemName: `Men's Singles`, groupIndex: 8 },
    '/menSingle/85+': { itemName: `Men's Singles`, groupIndex: 9 },
    '/menSingle/90+': { itemName: `Men's Singles`, groupIndex: 10 },
    '/menDoubles/40+': { itemName: `Men's Doubles`, groupIndex: 2 },
    '/menDoubles/50+': { itemName: `Men's Doubles`, groupIndex: 3 },
    '/menDoubles/60+': { itemName: `Men's Doubles`, groupIndex: 4 },
    '/menDoubles/65+': { itemName: `Men's Doubles`, groupIndex: 5 },
    '/menDoubles/70+': { itemName: `Men's Doubles`, groupIndex: 6 },
    '/menDoubles/75+': { itemName: `Men's Doubles`, groupIndex: 7 },
    '/menDoubles/80+': { itemName: `Men's Doubles`, groupIndex: 8 },
    '/menDoubles/85+': { itemName: `Men's Doubles`, groupIndex: 9 },
    '/menDoubles/90+': { itemName: `Men's Doubles`, groupIndex: 10 },
    '/menTeam/40+': { itemName: `Men's Team`, groupIndex: 2 },
    '/menTeam/50+': { itemName: `Men's Team`, groupIndex: 3 },
    '/menTeam/60+': { itemName: `Men's Team`, groupIndex: 4 },
    '/menTeam/65+': { itemName: `Men's Team`, groupIndex: 5 },
    '/menTeam/70+': { itemName: `Men's Team`, groupIndex: 6 },
    '/menTeam/75+': { itemName: `Men's Team`, groupIndex: 7 },
    '/menTeam/80+': { itemName: `Men's Team`, groupIndex: 8 },
    '/menTeam/85+': { itemName: `Men's Team`, groupIndex: 9 },
    '/menTeam/90+': { itemName: `Men's Team`, groupIndex: 10 },
    '/mixedDoubles/40+': { itemName: 'Mixed Doubles', groupIndex: 2 },
    '/mixedDoubles/50+': { itemName: 'Mixed Doubles', groupIndex: 3 },
    '/mixedDoubles/60+': { itemName: 'Mixed Doubles', groupIndex: 4 },
    '/mixedDoubles/65+': { itemName: 'Mixed Doubles', groupIndex: 5 },
    '/mixedDoubles/70+': { itemName: 'Mixed Doubles', groupIndex: 6 },
    '/mixedDoubles/75+': { itemName: 'Mixed Doubles', groupIndex: 7 },
    '/mixedDoubles/80+': { itemName: 'Mixed Doubles', groupIndex: 8 },
    '/mixedDoubles/85+': { itemName: 'Mixed Doubles', groupIndex: 9 },
    '/mixedDoubles/90+': { itemName: 'Mixed Doubles', groupIndex: 10 },
    '/womenSingle/40+': { itemName: `Women's Singles`, groupIndex: 2 },
    '/womenSingle/50+': { itemName: `Women's Singles`, groupIndex: 3 },
    '/womenSingle/60+': { itemName: `Women's Singles`, groupIndex: 4 },
    '/womenSingle/65+': { itemName: `Women's Singles`, groupIndex: 5 },
    '/womenSingle/70+': { itemName: `Women's Singles`, groupIndex: 6 },
    '/womenSingle/75+': { itemName: `Women's Singles`, groupIndex: 7 },
    '/womenSingle/80+': { itemName: `Women's Singles`, groupIndex: 8 },
    '/womenSingle/85+': { itemName: `Women's Singles`, groupIndex: 9 },
    '/womenSingle/90+': { itemName: `Women's Singles`, groupIndex: 10 },
    '/womenDoubles/40+': { itemName: `Women's Doubles`, groupIndex: 2 },
    '/womenDoubles/50+': { itemName: `Women's Doubles`, groupIndex: 3 },
    '/womenDoubles/60+': { itemName: `Women's Doubles`, groupIndex: 4 },
    '/womenDoubles/65+': { itemName: `Women's Doubles`, groupIndex: 5 },
    '/womenDoubles/70+': { itemName: `Women's Doubles`, groupIndex: 6 },
    '/womenDoubles/75+': { itemName: `Women's Doubles`, groupIndex: 7 },
    '/womenDoubles/80+': { itemName: `Women's Doubles`, groupIndex: 8 },
    '/womenDoubles/85+': { itemName: `Women's Doubles`, groupIndex: 9 },
    '/womenDoubles/90+': { itemName: `Women's Doubles`, groupIndex: 10 },
    '/womenTeam/40+': { itemName: `Women's Team`, groupIndex: 2 },
    '/womenTeam/50+': { itemName: `Women's Team`, groupIndex: 3 },
    '/womenTeam/60+': { itemName: `Women's Team`, groupIndex: 4 },
    '/womenTeam/65+': { itemName: `Women's Team`, groupIndex: 5 },
    '/womenTeam/70+': { itemName: `Women's Team`, groupIndex: 6 },
    '/womenTeam/75+': { itemName: `Women's Team`, groupIndex: 7 },
    '/womenTeam/80+': { itemName: `Women's Team`, groupIndex: 8 },
    '/womenTeam/85+': { itemName: `Women's Team`, groupIndex: 9 },
    '/womenTeam/90+': { itemName: `Women's Team`, groupIndex: 10 },
  };
  const currentPath = window.location.pathname;
  useEffect(() => {

    console.log(currentPath);

    if (currentPath === "/approved" || currentPath === "/addPlayer" || currentPath === "/pending") {
      const agegroup = currentPath || '';
      let activeGroupLabel = '';
      for (const group of navGroups) {
        const foundItem = group.items.find(item => item.link === agegroup);
        console.log(foundItem)

        if (foundItem) {
          activeGroupLabel = group.label;
          break; // Break out of the loop once the match is found
        }
      }
      console.log(activeGroupLabel);
      setActiveGroup(activeGroupLabel);
      if (pathToNavItemMap[currentPath]) {
        const { itemName, groupIndex } = pathToNavItemMap[currentPath];
        console.log(itemName)
        setActiveItem(itemName);
        setOpenSubMenus((prev) => ({ ...prev, [groupIndex]: true }));
      }
    }

    else if (currentPath === "/dashboard") {
      // const currentPath_s = "/" + currentPath.split("/")[1];
      const agegroup = currentPath.replace(/^\//, '') || '';
      let activeGroupLabel = '';
      for (const group of navGroups) {
        if (group.label.toLowerCase().includes(agegroup)) {
          activeGroupLabel = group.label; // Store the active group label if found
          break; // Exit the loop once a match is found
        }
      }
      console.log(activeGroupLabel);
      setActiveGroup(activeGroupLabel);
      if (pathToNavItemMap[currentPath]) {
        const { itemName, groupIndex } = pathToNavItemMap[currentPath];
        console.log(itemName)
        setActiveItem(itemName);
        setOpenSubMenus((prev) => ({ ...prev, [groupIndex]: true }));
      }

    }
    else {
      // const currentPath_s = "/" + currentPath.split("/")[1];
      const agegroup = currentPath.split("/").pop() || '';
      let activeGroupLabel = '';
      for (const group of navGroups) {
        if (group.label.includes(agegroup)) {
          activeGroupLabel = group.label; // Store the active group label if found
          break; // Exit the loop once a match is found
        }
      }
      console.log(activeGroupLabel);
      setActiveGroup(activeGroupLabel);
      if (pathToNavItemMap[currentPath]) {
        const { itemName, groupIndex } = pathToNavItemMap[currentPath];
        console.log(itemName)
        setActiveItem(itemName);
        setOpenSubMenus((prev) => ({ ...prev, [groupIndex]: true }));
      }
    }
  }, []);


  return (
    <>
      <div className={'header-main px-3 px-lg-4'}>
        <span id="menuSidebar" className="menu-link me-3 me-lg-4" onClick={toggleSidebar}>
          <i className="ri-menu-2-fill"></i>
        </span>

        <div className="me-auto">

        </div>


        <div className="dropdown dropdown-notification ms-3 ms-xl-4">
          <a href="#" className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside">
            <small>{notifications.length}</small>
            <i className="ri-notification-3-line"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-end mt-10-f">
            <div className="dropdown-menu-header">
              <h6 className="dropdown-menu-title">Notifications</h6>
            </div>
            <ul className="list-group">
              {notifications.map((notification) => (
                <li key={notification.id} className="list-group-item">
                  <div className={`avatar ${notification.avatar ? 'online' : ''}`}>
                    {notification.avatar ? <img src={notification.avatar} alt="" /> : <span className="avatar-initial bg-primary">N</span>}
                  </div>
                  <div className="list-group-body">
                    <p>{notification.message}</p>
                    <span>{notification.time}</span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="dropdown-menu-footer"><a href="">Show all Notifications</a></div>
          </div>
        </div>

        <div className="dropdown dropdown-profile ms-3 ms-xl-4">
          <a href="#" className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside">
            <div className="avatar online">
              <img src={profileimg} alt="" />
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end mt-10-f">
            <div className="dropdown-menu-body">
              <div className="avatar avatar-xl online mb-3">
                <img src={profileimg} alt="" />
              </div>
              <h5 className="mb-1 text-dark fw-semibold">JaiRam </h5>
              <p className="fs-sm text-secondary">Member</p>
              <nav className="nav">
                <a href=""><i className="ri-edit-2-line"></i> Edit Profile</a>
                <a href=""><i className="ri-profile-line"></i> View Profile</a>
              </nav>
              <hr />
              <nav className="nav">
                <a href=""><i className="ri-question-line"></i> Help Center</a>
                <a href=""><i className="ri-lock-line"></i> Privacy Settings</a>
                <a href=""><i className="ri-user-settings-line"></i> Account Settings</a>
                <a href=""><i className="ri-logout-box-r-line"></i> Log Out</a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className={`sidebar ${sidebarVisible ? 'sidebar-hide' : 'sidebar-show '}${footerMenuVisible ? 'footer-menu-show' : ''}`}>
        <div className="sidebar-header">
          <a href="../" className="sidebar-logo">APVTTC</a>
          <span id="menuSidebar" className="menu-link" onClick={toggleSidebar}>
            <i className="ri-menu-2-fill"></i>
          </span>
        </div>
        <div id="sidebarMenu" className="sidebar-body">
          <a className={`nav-d-label ${currentPath === '/dashboard' ? 'active' : ''}`} href="/dashboard"> Dashboard </a>
          {navGroups.map((group, groupIndex) => (
            <div className="nav-group" key={groupIndex}>
              <span className="nav-label" onClick={() => toggleSubMenu(groupIndex)}>
                {group.label}
              </span>
              <ul className="nav nav-sidebar" style={{ display: openSubMenus[groupIndex] ? 'flex' : 'none' }}>
                {group.items.map((item, itemIndex) => (
                  <li className="nav-item" key={itemIndex}>
                    <a
                      href={item.link}
                      className={`nav-sub-link ${(activeItem === item.name && activeGroup === group.label) ? 'active' : ''}`}
                      onClick={() => handleItemClick(item.name)}
                    >
                      {/* <i className={item.icon}></i> */}
                      <span>{item.name}</span>
                    </a>
                    {item.hasSub && (
                      <nav className="nav nav-sub" style={{ display: openSubMenus[groupIndex] ? 'flex' : 'none' }}>
                        {item.subItems?.map((subItem, subIndex) => (
                          <a href={subItem.link} className="nav-sub-link" key={subIndex}>
                            {subItem.name}
                          </a>
                        ))}
                      </nav>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img src={profileimg} alt="" />
            </div>
            <div className="sidebar-footer-body">
              <h6><a href="../pages/profile.html">JaiRam</a></h6>
              <p>Member</p>
            </div>
            <span id="sidebarFooterMenu" className="dropdown-link" onClick={toggleFooterMenu} ><i className="ri-arrow-down-s-line"></i></span>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <a href="#"><i className="ri-edit-2-line"></i> Edit Profile</a>
              <a href="#"><i className="ri-profile-line"></i> View Profile</a>
            </nav>
            <hr />
            <nav className="nav">
              <a href="#"><i className="ri-question-line"></i> Help Center</a>
              <a href="#"><i className="ri-lock-line"></i> Privacy Settings</a>
              <a href="#"><i className="ri-user-settings-line"></i> Account Settings</a>
              <a href="#"><i className="ri-logout-box-r-line"></i> Log Out</a>
            </nav>
          </div>
        </div>
      </div>
    </>

  );
};

export default Sidebar;
