import React, { useRef, useState } from "react";

import "./loginScreen.page.css";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/ui/loader/loader.components";
import logincover from "../../assets/images/logincover.jpg"

const LoginScreen = () => {
  const toast = useRef<Toast>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [code, setCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const handleSignIn = () => {
    // Navigate to the dashboard route
    navigate('/dashboard');
  };

  return (
    <>
      {isAdding && <Loader />}
      <div className="login-row row g-0">
        <div className="col-md-7 col-lg-5 col-xl-4 col-wrapper p-0">
          <div className="card card-sign">
            <div className="card-header">
              <a href="../" className="header-logo mb-5">APVTTC</a>
              <h3 className="card-title">Sign In</h3>
              <p className="card-text">Welcome! Please sign in to continue.</p>
            </div>
            <div className="card-body">
              <div className="mb-4">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your username"
                />
              </div>
              <div className="mb-4">
                <label className="form-label d-flex justify-content-between">
                  Password <a href="">Forgot password?</a>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter your password"
                />
              </div>
              <button type="button" className="btn btn-primary btn-sign d-flex align-items-center gap-2" onClick={handleSignIn}>Sign In</button>
            </div>
          </div>
        </div>
        <div className="col d-none d-lg-block p-0">
          <img
            src={logincover}
            className="auth-img"
            alt="Background"
          />
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
