/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/ui/navbar/sidenav.components";
import DoubleTable from "../../components/doublesTable/doubles.component";
import { useParams } from "react-router-dom";

const WomenDouble = () => {
    const {ageGroup} = useParams<{ageGroup:string}>();
    const [isAdding, setIsadding] = useState(false);
    const [players, setPlayers] = useState([]);     
    const [filters, setFilters] = useState({}); 
    const [loading, setLoading] = useState(true);   

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const fetchApprovedPlayers = async () => {
            setIsadding(true);
            setLoading(true);
            timer = setTimeout(async () => {
                const mockData:any = [
                    { S_No: 1, TTFIID: 101, Player_Name: 'Bora Ramji', Father_Name: 'Bora Venkat', Gender: 'Male', DOB: '21-Dec-1990', State: 'AP', Year: 2024, Status: 'Active' },
                    { S_No: 2, TTFIID: 102, Player_Name: 'John Doe', Father_Name: 'Doe Sr.', Gender: 'Male', DOB: '15-Jan-1992', State: 'AP', Year: 2024, Status: 'Inactive' },
                    { S_No: 3, TTFIID: 103, Player_Name: 'Jane Smith', Father_Name: 'Smith Sr.', Gender: 'Female', DOB: '10-Feb-1991', State: 'KA', Year: 2024, Status: 'Active' },
                    { S_No: 4, TTFIID: 104, Player_Name: 'Alice Brown', Father_Name: 'Brown Sr.', Gender: 'Female', DOB: '05-Mar-1990', State: 'TN', Year: 2024, Status: 'Inactive' },
                ];
                setPlayers(mockData);  
                setIsadding(false);
                setLoading(false);
            }, 500);
        };

        fetchApprovedPlayers();
        return () => clearTimeout(timer);
    }, []);

    const header = (
        <div className="table-header">
            Approved Players
            <div className="filters">
                <input 
                    type="text" 
                    placeholder="Search by Player Name" 
                    onChange={(e) => setFilters({ ...filters, Player_Name: { value: e.target.value, matchMode: 'contains' } })}
                />
                <input 
                    type="text" 
                    placeholder="Search by TTFIID" 
                    onChange={(e) => setFilters({ ...filters, TTFIID: { value: e.target.value, matchMode: 'contains' } })}
                />
            </div>
        </div>
    );

    return (
        <>
            <Sidebar />
            <main className="container-fluid">
                <div className="main main-app p-2 p-lg-3">
                    <div className="d-md-flex align-items-center justify-content-between mb-1">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><a href="/Dashboard">Veteran {ageGroup}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Women's Doubles {ageGroup}</li>
                            </ol>
                        </div>
                        <div className="d-flex gap-2 mt-3 mt-md-0">
                            <button type="button" className="btn btn-primary d-flex align-items-center gap-2">Export Women's Doubles {ageGroup}</button>
                        </div>
                    </div>
                    <DoubleTable />

                    <div className="main-footer mt-5">
                        <span>© 2024. Futuregen. All Rights Reserved.</span>
                        <span>Created by: <a href="https://futuregen.co.in" target="_blank" rel="noopener noreferrer">Futuregen</a></span>
                    </div>
                </div>
            </main>
        </>
    );
};

export default WomenDouble;
