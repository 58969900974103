import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../../api/approved.api';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './tournamentList.component.css'; 
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

interface Tournament {
  id: string | null;
  tournament: string;
  date: string;
  venue: string;
}

export default function TournamentList() {
    let emptyTournament: Tournament = {
        id: null,
        tournament: '',
        date: '',
        venue: '',
    };

    const [tournaments, setTournaments] = useState<Tournament[]>([]);
    const [tournamentDialog, setTournamentDialog] = useState<boolean>(false);
    const [deleteTournamentDialog, setDeleteTournamentDialog] = useState<boolean>(false);
    const [deleteTournamentsDialog, setDeleteTournamentsDialog] = useState<boolean>(false);
    const [tournament, setTournament] = useState<Tournament>(emptyTournament);
    const [selectedTournaments, setSelectedTournaments] = useState<Tournament[]>([]);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const toast = useRef<Toast>(null);

    useEffect(() => {
        ProductService.getTournamentList().then((data:any) => setTournaments(data));
    }, []);

    const openNew = () => {
        setTournament(emptyTournament);
        setSubmitted(false);
        setTournamentDialog(true);
    };

    const deleteTournament = () => {
        let _tournaments = tournaments.filter((val) => val.id !== tournament.id);
        setTournaments(_tournaments);
        setDeleteTournamentDialog(false);
        setTournament(emptyTournament);
        toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Tournament Deleted', life: 3000 });
    };

    const deleteSelectedTournaments = () => {
        let _tournaments = tournaments.filter((val) => !selectedTournaments.includes(val));
        setTournaments(_tournaments);
        setDeleteTournamentsDialog(false);
        setSelectedTournaments([]);
        toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Tournaments Deleted', life: 3000 });
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between table-header">
            <h5 className="m-0"></h5>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                 <InputText className='search-input' type="search" placeholder="Search..." onInput={(e) => {const target = e.target as HTMLInputElement; setGlobalFilter(target.value);}}  />
            </IconField>
        </div>
    );

    const deleteTournamentDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={() => setDeleteTournamentDialog(false)} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteTournament} />
        </React.Fragment>
    );

    const deleteTournamentsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={() => setDeleteTournamentsDialog(false)} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedTournaments} />
        </React.Fragment>
    );

    const actionTemplate = (rowData: Tournament) => {
        return (
            <Button  
                icon="bi bi-trash3" 
                className="p-button-danger p-button-sm" 
                onClick={() => window.location.href = `event-players.php?fd25c8bd686d308a4aab64ea98a261a7=${rowData.id}`} 
            />
        );
    };

    return (
        <div className="tournament-container">
            <Toast ref={toast} />
            <div className="tournament-list">
                <DataTable 
                    value={tournaments} 
                    stripedRows
                    dataKey="id" 
                    paginator 
                    rows={10} 
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tournaments" 
                    globalFilter={globalFilter} 
                    header={header}
                >
                    <Column field="id" header="S.No" sortable style={{ textAlign: "center", minWidth: '4rem' }} body={(rowData, options) => options.rowIndex + 1}></Column>
                    <Column field="tournament" header="Tournament" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="date" header="Date" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="venue" header="Venue" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column header="Action" body={actionTemplate} style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>

            <Dialog 
                visible={deleteTournamentDialog} 
                style={{ width: '32rem' }} 
                header="Confirm" 
                modal 
                footer={deleteTournamentDialogFooter} 
                onHide={() => setDeleteTournamentDialog(false)}
            >
                <div>Are you sure you want to delete this tournament?</div>
            </Dialog>
        </div>
    );
}
